import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PromotionsDisplay.css';
import CountdownTimer from './CountdownTimer';

const PromotionsDisplay = () => {
    const [promotions, setPromotions] = useState({ promotionalBundles: [], promotionalPackages: [] });
    const [categorizedPromotions, setCategorizedPromotions] = useState({});
    const [currentIndices, setCurrentIndices] = useState({});
    const [categoryViewStates, setCategoryViewStates] = useState({});

    useEffect(() => {
        fetchPromotions();
    }, []);

    const fetchPromotions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/promotion-display/promotions`);
            console.log('All promotions:', JSON.stringify(response.data, null, 2));
            setPromotions(response.data);
            separatePromotions(response.data);
        } catch (error) {
            console.error("Error fetching promotions:", error);
        }
    };

    const separatePromotions = (data) => {
        const categorized = {};
        const viewStates = {};
        const indices = {};
        
        const processItems = (items, type) => {
            items.forEach(promo => {
                const categoryId = promo.promotion_category_id;
                if (!categorized[categoryId]) {
                    categorized[categoryId] = {
                        bundles: [],
                        packages: [],
                        categoryDetails: promo.PromotionCategory,
                        hasPackages: false,
                        hasBundles: false,
                        defaultBundleIndex: -1,
                        defaultPackageIndex: -1
                    };
                    // Initialize view state based on what's available
                    viewStates[categoryId] = true; // Default to bundles
                }
                if (type === 'bundle') {
                    categorized[categoryId].hasBundles = true;
                    categorized[categoryId].bundles.push(promo);
                } else {
                    categorized[categoryId].hasPackages = true;
                    categorized[categoryId].packages.push(promo);
                }
            });
        };

        processItems(data.promotionalBundles, 'bundle');
        processItems(data.promotionalPackages, 'package');

        // Sort and set indices
        Object.keys(categorized).forEach(categoryId => {
            const category = categorized[categoryId];
            
            // Sort both arrays
            category.bundles.sort((a, b) => a.bundle_priority - b.bundle_priority);
            category.packages.sort((a, b) => a.package_priority - b.package_priority);
            
            // Set default indices
            category.defaultBundleIndex = category.bundles.findIndex(b => b.promo_bundle_default);
            category.defaultPackageIndex = category.packages.findIndex(p => p.promo_package_default);
            
            // Set initial index based on what's available
            if (category.hasBundles) {
                indices[categoryId] = category.defaultBundleIndex >= 0 ? category.defaultBundleIndex : 0;
                viewStates[categoryId] = true;
            } else if (category.hasPackages) {
                indices[categoryId] = category.defaultPackageIndex >= 0 ? category.defaultPackageIndex : 0;
                viewStates[categoryId] = false;
            }
        });

        setCurrentIndices(indices);
        setCategorizedPromotions(categorized);
        setCategoryViewStates(viewStates);
    };

    const renderPromoCard = (promo, isFireSale = false) => {
        if (!promo) return null;
        
        const isBundle = 'bundle_id' in promo;
        const providerCategoryImageUrl = promo.Package?.ProviderCategory?.prov_cat_image_url || promo.ProviderCategory?.prov_cat_image_url;
        const providerCategoryName = promo.Package?.ProviderCategory?.prov_cat_name || promo.ProviderCategory?.prov_cat_name;
        const endDate = new Date(promo.active_until);
        const promotionalPrice = isBundle ? promo.package_price : promo.sell_price_incl;
        const originalPrice = isBundle ? 
            promo.Package?.sell_price_incl : 
            promo.original_price || promo.Package?.sell_price_incl;

        return (
            <div key={isBundle ? promo.bundle_id : promo.package_id} className="card mb-3 text-center noselect">
                {providerCategoryImageUrl && <img src={process.env.REACT_APP_IMAGE_URL + '/' + providerCategoryImageUrl} alt="Provider" className="card-img-top" />}
                <div className="card-body">
                    <div className="timer-container">
                        <h3><CountdownTimer endDate={endDate} /></h3>
                    </div>
                    <h5 className="card-title">{isBundle ? promo.bundle_name : promo.package_name}</h5>
                    
                    <h4 className="card-text speeds-container">
                        <div className="speed-item">
                            <i className="fa-solid fa-cloud-arrow-down"></i> {promo.Package?.download_speed_mb || promo.download_speed_mb}Mbps
                        </div>
                        <div className="speed-item">
                            <i className="fa-solid fa-cloud-arrow-up"></i> {promo.Package?.upload_speed_mb || promo.upload_speed_mb}Mbps
                        </div>
                    </h4>
                    
                    <p className="card-text">
                        <i className="fa-solid fa-wrench"></i> Installation: 
                        {(isBundle ? promo.installation_price : promo.installation) === '0.00' || 
                         (isBundle ? promo.installation_price : promo.installation) === 0 ? (
                            <span className="text-danger"> Free!</span>
                        ) : (
                            ` R${isBundle ? promo.installation_price : promo.installation}`
                        )}
                    </p>
                   
                    <p className="card-text">
                        <i className="fa-solid fa-calendar-alt"></i> Term: {isBundle ? promo.Package?.term : promo.term} months
                    </p>
                    {isBundle && promo.bundle_description && (
                        <div className="card-text mb-3">
                            <i className="fa-solid fa-info-circle"></i> {promo.bundle_description}
                        </div>
                    )}

{((isBundle && promo.ValueAddedProducts && promo.ValueAddedProducts.length > 0) || 
                      (!isBundle && promo.Package?.ValueAddedProducts && promo.Package.ValueAddedProducts.length > 0)) && (
                        <div className="mt-3">
                            <h6>Included Products:</h6>
                            {(isBundle ? promo.ValueAddedProducts : promo.Package.ValueAddedProducts).map((product) => (
                                <div key={product.vap_id} className="d-flex align-items-center justify-content-center mb-2">
                                    <img src={process.env.REACT_APP_IMAGE_URL + '/' + product.vap_image_url} alt={product.vap_name} className="mr-2" style={{width: '50px', height: '50px', objectFit: 'contain'}} />
                                    <div>
                                        <p className="mb-0">
                                            {isBundle ? `${product.BundleValueAddedProduct.quantity} x ` : ''}
                                            {product.vap_name}
                                        </p>
                                        <p className="mb-0">
                                            {isBundle ? (
                                                product.BundleValueAddedProduct && product.BundleValueAddedProduct.price !== undefined ? (
                                                    product.BundleValueAddedProduct.price === '0.00' ? (
                                                        <span className="text-danger">Free!</span>
                                                    ) : (
                                                        <>
                                                            {parseFloat(product.BundleValueAddedProduct.price) < parseFloat(product.vap_sell) && (
                                                                <span className="text-muted"><del>R{product.vap_sell}</del> </span>
                                                            )}
                                                            <span className="text-danger">R{product.BundleValueAddedProduct.price} once off</span>
                                                        </>
                                                    )
                                                ) : (
                                                    <span>R{product.vap_sell}</span>
                                                )
                                            ) : (
                                                product.PromotionalPackageVAP && product.PromotionalPackageVAP.price !== undefined ? (
                                                    product.PromotionalPackageVAP.price === '0.00' ? (
                                                        <span className="text-danger">Free!</span>
                                                    ) : (
                                                        <>
                                                            {parseFloat(product.PromotionalPackageVAP.price) < parseFloat(product.vap_sell) && (
                                                                <span className="text-muted"><del>R{product.vap_sell}</del> </span>
                                                            )}
                                                            <span className="text-danger">R{product.PromotionalPackageVAP.price}</span>
                                                        </>
                                                    )
                                                ) : (
                                                    <span>R{product.vap_sell}</span>
                                                )
                                            )}
                                        </p>
                                        
                                    </div>
                                    
                                </div>
                            ))}
                            
                        </div>
                    )}



                    <h3 className="card-title">
                        {parseFloat(promotionalPrice) < parseFloat(originalPrice) && (
                            <span className="text-muted"><del>R{originalPrice}</del> </span>
                        )}
                        R{promotionalPrice} p/m
                    </h3>
                    <a 
                        href={`/coverage?promo_${isBundle ? 'bundle' : 'package'}_id=${isBundle ? promo.bundle_id : promo.package_id}`} 
                        className="btn btn-trusc-primary mt-3"
                    >
                        Sign Up
                    </a>
                </div>
            </div>
        );
    };

    const handleNext = (categoryId) => {
        const items = categoryViewStates[categoryId] 
            ? categorizedPromotions[categoryId].bundles 
            : categorizedPromotions[categoryId].packages;
        
        const currentIndex = currentIndices[categoryId];
        const nextIndex = currentIndex + 1;
        
        if (nextIndex < items.length) {
            setCurrentIndices(prev => ({
                ...prev,
                [categoryId]: nextIndex
            }));
        }
    };

    const handlePrev = (categoryId) => {
        const currentIndex = currentIndices[categoryId];
        const prevIndex = currentIndex - 1;
        
        if (prevIndex >= 0) {
            setCurrentIndices(prev => ({
                ...prev,
                [categoryId]: prevIndex
            }));
        }
    };

    const toggleView = (categoryId) => {
        setCategoryViewStates(prev => ({
            ...prev,
            [categoryId]: !prev[categoryId]
        }));
    };

    return (
        <div className="container mt-4">
            {Object.entries(categorizedPromotions)
                .sort(([,a], [,b]) => a.categoryDetails.promotion_category_priority - b.categoryDetails.promotion_category_priority)
                .map(([categoryId, category]) => (
                    <div key={categoryId} className="promotion-category-banner mb-1">
                        <h2 
                            className="promotion-category-title"
                            style={{
                                backgroundColor: category.categoryDetails.promotion_category_header_background_color,
                                color: category.categoryDetails.promotion_category_header_text_color
                            }}
                        >
                            {category.categoryDetails.promotion_category_name}
                        </h2>
                        {category.categoryDetails.promotion_category_description && (
                            <div 
                                className="promotion-category-description text-center mb-1"
                                style={{
                                    padding: '10px',
                                    fontSize: '1.1rem'
                                }}
                            >
                                {category.categoryDetails.promotion_category_description}
                            </div>
                        )}
                        
                        {category.hasBundles && category.hasPackages && (
                            <div className="toggle-buttons mb-3">
                                <button 
                                    className={`btn ${categoryViewStates[categoryId] ? 'btn-trusc-primary' : 'btn-trusc-secondary'}`}
                                    onClick={() => toggleView(categoryId)}
                                >
                                    Bundles
                                </button>
                                <button 
                                    className={`btn ${!categoryViewStates[categoryId] ? 'btn-trusc-primary' : 'btn-trusc-secondary'}`}
                                    onClick={() => toggleView(categoryId)}
                                >
                                    Packages Only
                                </button>
                            </div>
                        )}

                        {((categoryViewStates[categoryId] ? category.bundles : category.packages) || []).length > 0 ? (
                            <div className="d-flex align-items-center justify-content-center">
                                {((categoryViewStates[categoryId] ? category.bundles : category.packages) || []).length > 1 && (
                                    <button 
                                        className="btn btn-trusc-carousel mr-3" 
                                        onClick={() => handlePrev(categoryId)}
                                        disabled={currentIndices[categoryId] === 0}
                                    >
                                        &lt;
                                    </button>
                                )}
                                <div className="flex-grow-1" style={{maxWidth: '500px'}}>
                                    {renderPromoCard(
                                        (categoryViewStates[categoryId] ? category.bundles : category.packages)[currentIndices[categoryId]], 
                                        category.categoryDetails.promotion_category_name === "Fire Sale"
                                    )}
                                </div>
                                {((categoryViewStates[categoryId] ? category.bundles : category.packages) || []).length > 1 && (
                                    <button 
                                        className="btn btn-trusc-carousel ml-3" 
                                        onClick={() => handleNext(categoryId)}
                                        disabled={currentIndices[categoryId] === 
                                            (categoryViewStates[categoryId] 
                                                ? category.bundles.length - 1 
                                                : category.packages.length - 1)}
                                    >
                                        &gt;
                                    </button>
                                )}
                            </div>
                        ) : (
                            <p className="text-center">No {categoryViewStates[categoryId] ? 'bundles' : 'packages'} available</p>
                        )}
                    </div>
                ))}
        </div>
    );
};

export default PromotionsDisplay;
