import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CoverageMap from './components/CoverageMap';
import PackagesDisplay from './components/PackagesDisplay';
import 'bootstrap/dist/css/bootstrap.min.css';
import SignUpSubmittedPage from './components/SignUpSubmittedPage';
import PromotionsDisplay from './components/PromotionsDisplay';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import './App.css';
import Testimonials from './components/Testimonials';
import NewsLetter from './components/NewsLetter';
import HomePage from './pages/HomePage';
import ContactPage from './pages/ContactPage';
import AboutPage from './pages/AboutPage';
import TechnologyPage from './pages/TechnologyPage';
import { checkTeamMemberSaleUrl } from './utils/salesTracking';
import TeamMemberPage from './pages/TeamMemberPage';
import TeamMemberInfo from './components/TeamMemberInfo';

const App = () => {
    useEffect(() => {
        checkTeamMemberSaleUrl(undefined);
    }, []);

    return (
        <Router>
            <div className="app-container">
                <NavBar />
                <main className="main-content">
                    <Routes>
                        <Route path="/" element={<HomePage />} />

                        <Route path="coverage/:id?" element={<CoverageMap />} />
                        <Route path="/packages-display" element={<PackagesDisplay />} />
                        <Route path="/signup-submitted" element={<SignUpSubmittedPage />} />
                        <Route path="/promotions" element={<PromotionsDisplay />} />
                        <Route path="/testimonials" element={<Testimonials />} />
                        <Route path="/newsletter" element={<NewsLetter/>} />
                        <Route path="/contact" element={<ContactPage />} />
                        <Route path="/about" element={<AboutPage />} />
                        <Route path="/technology" element={<TechnologyPage />} />
                        <Route path="/:saleUrl" element={<TeamMemberPage />} />
                    </Routes>
                </main>
                <Footer />
            </div>
        </Router>
    );
};

export default App;
