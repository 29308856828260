import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const CoverageMap = () => {
    const [map, setMap] = useState(null);
    const [marker, setMarker] = useState(null);
    const [address, setAddress] = useState('');
    const [results, setResults] = useState(''); // Define results state
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    const urlParams = new URLSearchParams(window.location.search);
    const packageId = urlParams.get('package_id');
    const bundleId = urlParams.get('bundle_id');
    const promoPackageId = urlParams.get('promo_package_id');
    const promoBundleId = urlParams.get('promo_bundle_id');

    const selectedId = packageId || bundleId || promoPackageId || promoBundleId;

    useEffect(() => {
        const initMap = () => {
            const initialLocation = { lat: -26.2041, lng: 28.0473 }; // Johannesburg
            const googleMap = new window.google.maps.Map(document.getElementById("map"), {
                zoom: 15,
                center: initialLocation,
            });

            const googleMarker = new window.google.maps.Marker({
                position: initialLocation,
                map: googleMap,
                draggable: true,
            });

            const geocoder = new window.google.maps.Geocoder();

            googleMarker.addListener("dragend", (event) => {
                const latLng = event.latLng;
                geocoder.geocode({ location: latLng }, (results, status) => {
                    if (status === "OK" && results[0]) {
                        setAddress(results[0].formatted_address);
                    } else {
                        setAddress(`${latLng.lat()}, ${latLng.lng()}`);
                    }
                });
            });

            setMap(googleMap);
            setMarker(googleMarker);

            // Try to get user's location
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        const latLng = new window.google.maps.LatLng(latitude, longitude);
                        googleMap.setCenter(latLng);
                        googleMarker.setPosition(latLng);
                        
                        geocoder.geocode({ location: latLng }, (results, status) => {
                            if (status === "OK" && results[0]) {
                                setAddress(results[0].formatted_address);
                            } else {
                                setAddress(`${latitude}, ${longitude}`);
                            }
                        });
                    },
                    (error) => {
                        console.error("Error getting user location:", error);
                    }
                );
            }
        };

        initMap();
    }, []);

    useEffect(() => {
        if (map && marker) { // Ensure marker is defined
            const input = document.getElementById("address");
            const autocomplete = new window.google.maps.places.Autocomplete(input);
            autocomplete.bindTo("bounds", map);

            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                if (place.geometry) {
                    map.setCenter(place.geometry.location);
                    marker.setPosition(place.geometry.location);
                    setAddress(place.formatted_address);
                }
            });
        }
    }, [map, marker]); // Add marker to the dependency array

    const checkCoverage = async () => {
        setIsLoading(true);
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ address }, async (results, status) => {
            if (status === "OK") {
                const position = marker.getPosition();
                const coordinates = {
                    latitude: position.lat(),
                    longitude: position.lng(),
                };

                try {
                    console.log(process.env.REACT_APP_API_URL);
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/coverage`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(coordinates),
                    });

                    const resultsData = await response.json();
                    console.log('API Response:', resultsData);

                    const urlParams = new URLSearchParams(window.location.search);
                    const techClassId = urlParams.get('tech_class_id');

                    if (resultsData.error || (resultsData && resultsData.technology_classes)) {
                        navigate('/packages-display', { 
                            state: { 
                                coverageData: resultsData,
                                selectedId: selectedId,
                                selectedType: packageId ? 'package' : bundleId ? 'bundle' : promoPackageId ? 'promo_package' : 'promo_bundle',
                                techClassId: techClassId
                            } 
                        });
                    } else {
                        alert('Invalid response format. Please try again.');
                    }
                } catch (error) {
                    console.error('Error during coverage check:', error);
                    alert('An error occurred during the coverage check. Please try again.');
                }
            } else {
                alert("Geocode was not successful for the following reason: " + status);
            }
            setIsLoading(false);
        });
    };

    const getUserLocation = () => {
        if (navigator.geolocation) {
            setIsLoading(true);
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const latLng = new window.google.maps.LatLng(latitude, longitude);
                    map.setCenter(latLng);
                    marker.setPosition(latLng);

                    // Use Geocoding API to get the address
                    const geocoder = new window.google.maps.Geocoder();
                    geocoder.geocode({ location: latLng }, (results, status) => {
                        if (status === "OK" && results[0]) {
                            setAddress(results[0].formatted_address);
                        } else {
                            setAddress(`${latitude}, ${longitude}`);
                        }
                        setIsLoading(false);
                    });
                },
                (error) => {
                    console.error("Error getting user location:", error);
                    alert("Unable to retrieve your location. Please enter an address manually.");
                    setIsLoading(false);
                }
            );
        } else {
            alert("Geolocation is not supported by your browser. Please enter an address manually.");
        }
    };

    return (
        <div className="container mt-1 p-4" style={{ backgroundColor: 'white', borderRadius: '0px', marginTop: '0px', maxWidth: '1280px' }}>
            <h1 className="text-center mb-4">Coverage Check</h1>
            <h5 className="text-center mb-4">Please enter your current address or gps coordinates / use current location button / drag the marker on the map to where you want service.</h5>
            <div className="input-group mb-3">
            <button 
                    className="btn btn-primary" 
                    onClick={getUserLocation}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            Getting Location...
                        </>
                    ) : (
                        'Use Current Location'
                    )}
                </button>
                <input
                    id="address"
                    type="text"
                    className="form-control"
                    placeholder="Enter an address or coordinates (lat, lon)"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />
               
                
            </div>
            <div className="d-flex justify-content-center">
            <button 
                id="checkCoverage" 
                className="btn btn-danger btn-block text-center mb-3" 
                onClick={checkCoverage} 
                disabled={isLoading}
            >
                {isLoading ? (
                    <>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Checking...
                    </>
                ) : (
                    'Show Me What You Have'
                )}
                </button>
            </div>
            <div id="map" className="mb-3" style={{ height: '500px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}></div>
    

        </div>
    );
};

export default CoverageMap;
