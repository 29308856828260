import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import logo from '../assets/Trusc_black.webp';

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-general">
            <h3>General</h3>
            <ul>
              <li><Link to="/terms">Open Access</Link></li>
              <li><Link to="/wapa">Investors</Link></li>
              <li><Link to="/privacy">WAPA</Link></li>
              <li><Link to="/acceptable-use">Team</Link></li>
              
            </ul>
          </div>
          <div className="footer-contact">
  <h3>Follow us on Social Media</h3>
  <ul className="social-media-list">
    <li>
      <Link to="https://www.facebook.com/trusc.net">
        <i className="fab fa-facebook"></i>
        <span>Facebook </span>
      </Link>
    </li>
    <li>
      <Link to="https://twitter.com/truscnet">
        <i className="fab fa-twitter"></i>
        <span>Twitter </span>
      </Link>
    </li>
    <li>
      <Link to="https://www.instagram.com/truscnet/">
        <i className="fab fa-instagram"></i>
        <span>Instagram </span>
      </Link>
    </li>
    <li>
      <Link to="https://www.youtube.com/@truscnet">
        <i className="fab fa-youtube"></i>
        <span>Youtube </span>
      </Link>
    </li>
  </ul>
</div>
          <div className="footer-info">
            <h3>Information</h3>
            <ul>
              <li><Link to="/terms">Terms & Conditions</Link></li>
              <li><Link to="/privacy">Privacy Policy</Link></li>
              <li><Link to="/acceptable-use">Acceptable Use Policy</Link></li>
              <li><Link to="/licenses">Licenses</Link></li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
