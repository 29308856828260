import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ endDate }) => {
    const [timeLeft, setTimeLeft] = useState({});

    useEffect(() => {
        const calculateTimeLeft = () => {
            const difference = +new Date(endDate) - +new Date();
            console.log('End date:', endDate, 'Difference:', difference);
            
            if (difference > 0) {
                return {
                    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                    minutes: Math.floor((difference / 1000 / 60) % 60),
                    seconds: Math.floor((difference / 1000) % 60)
                };
            }
            return {};
        };

        const timer = setInterval(() => {
            const newTimeLeft = calculateTimeLeft();
            console.log('New time left:', newTimeLeft);
            setTimeLeft(newTimeLeft);
        }, 1000);

        return () => clearInterval(timer);
    }, [endDate]);

    const formatTime = (time) => (time !== undefined ? time.toString().padStart(2, '0') : '00');

    if (Object.keys(timeLeft).length === 0) {
        return <span></span>;
    }

    return (
        <div className="countdown-timer">
            <span>Ends in: </span>
            {timeLeft.days > 0 && <span>{timeLeft.days}d </span>}
            <span>{formatTime(timeLeft.hours)}h </span>
            <span>{formatTime(timeLeft.minutes)}m </span>
            <span>{formatTime(timeLeft.seconds)}s</span>
        </div>
    );
};

export default CountdownTimer;
