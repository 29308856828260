import React, { useEffect } from 'react';
import PromotionsDisplay from '../components/PromotionsDisplay';
import Testimonials from '../components/Testimonials';
import NewsLetter from '../components/NewsLetter';
import TeamMemberInfo from '../components/TeamMemberInfo';
import './HomePage.css';

const HomePage = () => {
  return (
    <div className="home-page">
      

      <section className="promotions">
        <PromotionsDisplay />
      </section>

      <TeamMemberInfo />

      

      
    </div>
  );
};

export default HomePage;
