import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Card, Button } from 'react-bootstrap';


const SignUpSubmittedPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { ticketNumber } = location.state || {};

  return (
    <Container className="mt-5">
      <Card className="text-center">
        <Card.Body>
          
          <Card.Title as="h2">Thank You for Signing Up!</Card.Title>
          <Card.Text as="h4">Your application has been submitted successfully</Card.Text>
          <Card.Text className="lead">
            Your ticket number is: <strong>{ticketNumber}</strong>
          </Card.Text>
          <Card.Text>We'll process your application and get back to you shortly.</Card.Text>
         
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SignUpSubmittedPage;