import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PackagesDisplay.css';
import SignUpForm from './SignUpForm.js';

const PackagesDisplay = () => {
    const location = useLocation();
    const coverageData = location.state?.coverageData;

    const [data, setData] = useState(null);
    const [selectedTechClass, setSelectedTechClass] = useState(null);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [sortedPackages, setSortedPackages] = useState([]);
    const [sortedBundles, setSortedBundles] = useState([]);
    const [filteredPackages, setFilteredPackages] = useState([]);
    const [filteredBundles, setFilteredBundles] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [viewBundles, setViewBundles] = useState(true);
    const [techClassIndex, setTechClassIndex] = useState(0);
    const [providerIndex, setProviderIndex] = useState(0);
    const [showSignUpForm, setShowSignUpForm] = useState(false);
    const [selectedPackageOrBundle, setSelectedPackageOrBundle] = useState(null);
    const [gpsCoordinates, setGpsCoordinates] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [selectedId, setSelectedId] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [notFoundMessage, setNotFoundMessage] = useState('');
    const [noServicesMessage, setNoServicesMessage] = useState('');
    const [currentIndices, setCurrentIndices] = useState({});
    const [categoryViewStates, setCategoryViewStates] = useState({});

    const providerRef = useRef(null);
    const packagesRef = useRef(null);

    useEffect(() => {
        if (coverageData) {
            if (coverageData.error === "No services found in the response") {
                setNoServicesMessage("No services are currently available at your location.");
            } else {
                const sortedTechClasses = coverageData.technology_classes.sort((a, b) => a.tech_class_priority - b.tech_class_priority);
                setData({...coverageData, technology_classes: sortedTechClasses});
                setLatitude(coverageData.coverageCheck.latitude);
                setLongitude(coverageData.coverageCheck.longitude);
                
                const techClassId = location.state?.techClassId;
                if (techClassId) {
                    const selectedTech = sortedTechClasses.find(tech => tech.tech_class_id === parseInt(techClassId));
                    if (selectedTech) {
                        setSelectedTechClass(selectedTech);
                    } else {
                        setNoServicesMessage(`The selected technology class is not available at this address. Please check our other options below.`);
                        setSelectedTechClass(sortedTechClasses[0]);
                    }
                } else if (sortedTechClasses.length > 0) {
                    setSelectedTechClass(sortedTechClasses[0]);
                } else {
                    setNoServicesMessage("No services are currently available at your location.");
                }
                
                setSelectedId(location.state?.selectedId);
                setSelectedType(location.state?.selectedType);
            }
        }
    }, [coverageData, location.state]);

    useEffect(() => {
        if (selectedTechClass) {
            const sortedProviders = selectedTechClass.providers.sort((a, b) => {
                const aPriority = a.provider_categories[0]?.prov_cat_priority || 0;
                const bPriority = b.provider_categories[0]?.prov_cat_priority || 0;
                return aPriority - bPriority;
            });
            setSelectedProvider(sortedProviders[0]);
            setProviderIndex(0);
        }
    }, [selectedTechClass]);

    useEffect(() => {
        if (selectedProvider && selectedProvider.provider_categories) {
            const viewStates = {};
            const indices = {};
            
            selectedProvider.provider_categories.forEach(providerCategory => {
                if (!providerCategory) return;
                
                // Sort packages and bundles by priority
                const packages = providerCategory.packages ? 
                    [...providerCategory.packages].sort((a, b) => a.package_priority - b.package_priority) : [];
                const bundles = providerCategory.bundles ? 
                    [...providerCategory.bundles].sort((a, b) => a.bundle_priority - b.bundle_priority) : [];

                // Find default indices
                const defaultBundleIndex = bundles.findIndex(b => b.bundle_default === true);
                const defaultPackageIndex = packages.findIndex(p => p.package_default === true);

                if (providerCategory.prov_cat_id) {
                    // Prefer bundles if available
                    if (bundles.length > 0) {
                        viewStates[providerCategory.prov_cat_id] = true;
                        indices[providerCategory.prov_cat_id] = defaultBundleIndex >= 0 ? defaultBundleIndex : 0;
                    } else if (packages.length > 0) {
                        viewStates[providerCategory.prov_cat_id] = false;
                        indices[providerCategory.prov_cat_id] = defaultPackageIndex >= 0 ? defaultPackageIndex : 0;
                    }
                }
            });

            setCategoryViewStates(viewStates);
            setCurrentIndices(indices);
        }
    }, [selectedProvider]);

    useEffect(() => {
        if (selectedId && selectedType && data) {
            const findItem = (items) => items.find(item => item[`${selectedType}_id`] == selectedId);
            
            let itemFound = false;
            for (const techClass of data.technology_classes) {
                for (const provider of techClass.providers) {
                    for (const category of provider.provider_categories) {
                        let item;
                        switch (selectedType) {
                            case 'package':
                                item = findItem(category.packages);
                                break;
                            case 'bundle':
                                item = findItem(category.bundles);
                                break;
                            case 'promo_package':
                                item = findItem(category.promotional_packages);
                                break;
                            case 'promo_bundle':
                                item = findItem(category.promotional_bundles);
                                break;
                        }
                        if (item) {
                            setSelectedTechClass(techClass);
                            setSelectedProvider(provider);
                            setSelectedPackageOrBundle(item);
                            setShowSignUpForm(true);
                            itemFound = true;
                            return;
                        }
                    }
                }
            }
            
            if (!itemFound) {
                setNotFoundMessage("<strong style='font-size: 1.2em;'>The selected product is not available at your location.<br><br>Please select an available product from the list below.</strong>");
                // Set the first available tech class and provider
                if (data.technology_classes.length > 0) {
                    setSelectedTechClass(data.technology_classes[0]);
                    if (data.technology_classes[0].providers.length > 0) {
                        setSelectedProvider(data.technology_classes[0].providers[0]);
                    }
                }
            }
        }
    }, [selectedId, selectedType, data]);

    useEffect(() => {
        setCurrentIndex(0);
    }, [selectedProvider]);

    const handlePrevTechClass = () => {
        if (data && data.technology_classes && techClassIndex > 0) {
            const sortedClasses = [...data.technology_classes].sort((a, b) => a.tech_class_priority - b.tech_class_priority);
            const prevIndex = techClassIndex - 1;
            setTechClassIndex(prevIndex);
            setSelectedTechClass(sortedClasses[prevIndex]);
        }
    };

    const handleNextTechClass = () => {
        if (data && data.technology_classes && techClassIndex < data.technology_classes.length - 1) {
            const sortedClasses = [...data.technology_classes].sort((a, b) => a.tech_class_priority - b.tech_class_priority);
            const nextIndex = techClassIndex + 1;
            setTechClassIndex(nextIndex);
            setSelectedTechClass(sortedClasses[nextIndex]);
        }
    };

    const handlePrevProvider = () => {
        if (selectedTechClass && selectedTechClass.providers && providerIndex > 0) {
            const sortedProviders = [...selectedTechClass.providers].sort((a, b) => {
                const aPriority = a.provider_categories[0]?.prov_cat_priority || 0;
                const bPriority = b.provider_categories[0]?.prov_cat_priority || 0;
                return aPriority - bPriority;
            });
            const prevIndex = providerIndex - 1;
            setProviderIndex(prevIndex);
            setSelectedProvider(sortedProviders[prevIndex]);
        }
    };

    const handleNextProvider = () => {
        if (selectedTechClass && selectedTechClass.providers && providerIndex < selectedTechClass.providers.length - 1) {
            const sortedProviders = [...selectedTechClass.providers].sort((a, b) => {
                const aPriority = a.provider_categories[0]?.prov_cat_priority || 0;
                const bPriority = b.provider_categories[0]?.prov_cat_priority || 0;
                return aPriority - bPriority;
            });
            const nextIndex = providerIndex + 1;
            setProviderIndex(nextIndex);
            setSelectedProvider(sortedProviders[nextIndex]);
        }
    };

    const handleSignUp = (packageData) => {
        setSelectedPackageOrBundle(packageData);
        setShowSignUpForm(true);
    };

    const handleBundleSignUp = (bundleData) => {
        setSelectedPackageOrBundle(bundleData);
        setShowSignUpForm(true);
    };

    const handleCloseSignUpForm = () => {
        setShowSignUpForm(false);
        setSelectedPackageOrBundle(null);
    };

    const toggleView = (categoryId) => {
        setCategoryViewStates(prev => ({
            ...prev,
            [categoryId]: !prev[categoryId]
        }));
        setCurrentIndices(prev => ({
            ...prev,
            [categoryId]: 0
        }));
    };

    const handleNextCard = (categoryIndex) => {
        const providerCategory = selectedProvider.provider_categories[categoryIndex];
        if (!providerCategory) return;

        const currentArray = categoryViewStates[providerCategory.prov_cat_id]
            ? providerCategory.bundles || []
            : providerCategory.packages || [];

        if (currentArray.length > 0) {
            const currentIndex = currentIndices[providerCategory.prov_cat_id] || 0;
            const nextIndex = (currentIndex + 1) % currentArray.length;
            setCurrentIndices(prev => ({
                ...prev,
                [providerCategory.prov_cat_id]: nextIndex
            }));
        }
    };

    const handlePrevCard = (categoryIndex) => {
        const providerCategory = selectedProvider.provider_categories[categoryIndex];
        if (!providerCategory) return;

        const currentArray = categoryViewStates[providerCategory.prov_cat_id]
            ? providerCategory.bundles || []
            : providerCategory.packages || [];

        if (currentArray.length > 0) {
            const currentIndex = currentIndices[providerCategory.prov_cat_id] || 0;
            const prevIndex = (currentIndex - 1 + currentArray.length) % currentArray.length;
            setCurrentIndices(prev => ({
                ...prev,
                [providerCategory.prov_cat_id]: prevIndex
            }));
        }
    };

    return (
        <div className="container mt-4">
            <div className="address-section">
                <h1 className="text-center">Service Coverage For:</h1>
                <h3 className="text-center"> {data && data.coverageCheck.address}</h3>
                {notFoundMessage && (
                    <div className="alert alert-warning text-center" role="alert" dangerouslySetInnerHTML={{ __html: notFoundMessage }} />
                )}
                {noServicesMessage && (
                    <div className="alert alert-info text-center" role="alert">
                        {noServicesMessage}
                    </div>
                )}
            </div>
            
            {data && data.technology_classes.length > 0 && (
                <>
                <div className="section-header noselect">
                        <h2 className="text-center">Step 1 - Choose Use Case</h2>
                        </div>
                    <div className="tech-class-section noselect">
                        
                        <div className="d-flex justify-content-center align-items-center">
                            <button 
                                className="btn btn-trusc-carousel" 
                                onClick={handlePrevTechClass}
                                disabled={techClassIndex === 0}
                            >❮</button>
                            {data && data.technology_classes[techClassIndex] && (
                                <div className="text-center mx-4 noselect">
                                    <img src={process.env.REACT_APP_IMAGE_URL + '/' + data.technology_classes[techClassIndex].image_url} alt={data.technology_classes[techClassIndex].tech_class_name} style={{ width: '150px', height: '150px', objectFit: 'contain' }} />
                                    <div><h3>{data.technology_classes[techClassIndex].tech_class_name}</h3></div>
                                </div>
                            )}
                            <button 
                                className="btn btn-trusc-carousel" 
                                onClick={handleNextTechClass}
                                disabled={techClassIndex === (data?.technology_classes?.length || 1) - 1}
                            >❯</button>
                        </div>
                    </div>

                    {selectedTechClass && (

                        <div className="provider-section noselect" ref={providerRef}>
                            <div className="section-header">
                                <h2 className="text-center">Step 2 - Choose Your {selectedTechClass.tech_class_name} Provider</h2>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <button 
                                    className="btn btn-trusc-carousel" 
                                    onClick={handlePrevProvider}
                                    disabled={providerIndex === 0}
                                >❮</button>
                                {selectedTechClass.providers[providerIndex] && (
                                    <div className="text-center mx-4 noselect">
                                        <img 
                                            src={process.env.REACT_APP_IMAGE_URL + '/' + selectedTechClass.providers[providerIndex].provider_image_url} 
                                            alt={selectedTechClass.providers[providerIndex].provider_name} 
                                            style={{ width: '150px', height: '150px', objectFit: 'contain' }} 
                                        />
                                        
                                    </div>
                                )}
                                <button 
                                    className="btn btn-trusc-carousel" 
                                    onClick={handleNextProvider}
                                    disabled={providerIndex === (selectedTechClass?.providers?.length || 1) - 1}
                                >❯</button>
                            </div>
                        </div>
                    )}

                    {selectedProvider && (
                        <div className="bundle-package-section noselect" ref={packagesRef}>
                            {selectedProvider.provider_categories.map((providerCategory, categoryIndex) => (
                                <div key={providerCategory.prov_cat_id} className="provider-category-section mb-5">
                                    <div className="section-header">
                                        <h2 className="text-center">
                                            <span className="category-heading"> {providerCategory.prov_cat_name} </span>
                                            | {categoryViewStates[providerCategory.prov_cat_id] ? 'Bundles' : 'Packages'}
                                        </h2>
                                    </div>
                                    <div className="category-description text-center mb-3">
                <p>{providerCategory.prov_cat_description || ''}</p>
            </div>
                                    
                                    {providerCategory.bundles?.length > 0 && providerCategory.packages?.length > 0 && (
                                        <div className="d-flex justify-content-center mb-3">
                                            <button 
                                                className={`btn ${categoryViewStates[providerCategory.prov_cat_id] ? 'btn-trusc-primary' : 'btn-trusc-secondary'}`} 
                                                onClick={() => toggleView(providerCategory.prov_cat_id)}
                                            >
                                                Bundles
                                            </button>
                                            <button 
                                                className={`btn ${!categoryViewStates[providerCategory.prov_cat_id] ? 'btn-trusc-primary' : 'btn-trusc-secondary'}`} 
                                                onClick={() => toggleView(providerCategory.prov_cat_id)}
                                            >
                                                Packages Only
                                            </button>
                                        </div>
                                    )}

                                    <p className="text-center description-text">
                                        {categoryViewStates[providerCategory.prov_cat_id]
                                            ? ""
                                            : ""}
                                    </p>

                                    <div className="package-cards-container">
                                        <button 
                                            className="btn btn-trusc-carousel" 
                                            onClick={(e) => {
                                                if (!e.currentTarget.disabled) {
                                                    handlePrevCard(categoryIndex);
                                                }
                                            }}
                                            disabled={currentIndices[providerCategory.prov_cat_id] === 0}
                                        >❮</button>
                                        <div className="package-cards noselect">
                                            {categoryViewStates[providerCategory.prov_cat_id] ? (
                                                providerCategory.bundles?.length > 0 && currentIndices[providerCategory.prov_cat_id] !== undefined ? (
                                                    <div className="package-card">
                                                        <img 
                                                            src={process.env.REACT_APP_IMAGE_URL + '/' + providerCategory.prov_cat_image_url} 
                                                            alt={providerCategory.bundles[currentIndices[providerCategory.prov_cat_id]]?.bundle_description || ''} 
                                                            className="card-img-top" 
                                                        />
                                                        <div className="card-body">
                                                        <h5 className="card-title">
    {categoryViewStates[providerCategory.prov_cat_id] 
        ? providerCategory.bundles[currentIndices[providerCategory.prov_cat_id] || 0].bundle_name
        : providerCategory.packages[currentIndices[providerCategory.prov_cat_id] || 0].package_name}
</h5>


                                                            <div className="card-speeds">
                                                                <p>
                                                                    <i className="fa-solid fa-cloud-arrow-down"></i> {providerCategory.bundles[currentIndices[providerCategory.prov_cat_id] || 0].download_speed_mb} Mbps
                                                                </p>
                                                                <p>
                                                                    <i className="fa-solid fa-cloud-arrow-up"></i> {providerCategory.bundles[currentIndices[providerCategory.prov_cat_id] || 0].upload_speed_mb} Mbps
                                                                </p>
                                                            </div>

                                                            

                                                            <div className="card-costs">
                                                                <p>
                                                                    <span className="cost-heading">Installation Cost: </span>
                                                                    {parseFloat(providerCategory.bundles[currentIndices[providerCategory.prov_cat_id] || 0].installation_price) === 0 ? (
                                                                        <span style={{ color: 'red' }}>Free!</span>
                                                                    ) : (
                                                                        <>R{providerCategory.bundles[currentIndices[providerCategory.prov_cat_id] || 0].installation_price}<span className="cost-post-text">/Once Off</span></>
                                                                    )}
                                                                </p>
                                                                <p className="card-text">
                                                                    <i className="fa-solid fa-calendar-alt"></i> Term: {providerCategory.bundles[currentIndices[providerCategory.prov_cat_id] || 0].term} months
                                                                </p>
                                                            </div>
                                                            {providerCategory.bundles[currentIndices[providerCategory.prov_cat_id] || 0].bundle_description && (
    <div className="card-text mb-3">
        <i className="fa-solid fa-info-circle"></i> {providerCategory.bundles[currentIndices[providerCategory.prov_cat_id] || 0].bundle_description}
    </div>
)}
                                                            
                                    
                                                            <div className="bundle-products">
                                                                <h6>Included Products:</h6>
                                                                {providerCategory.bundles[currentIndices[providerCategory.prov_cat_id] || 0].ValueAddedProducts.map((product) => (
                                                                    <div key={product.vap_id} className="d-flex align-items-center justify-content-center mb-2">
                                                                        <img src={process.env.REACT_APP_IMAGE_URL + '/' + product.vap_image_url} alt={product.vap_name} className="mr-2" style={{width: '50px', height: '50px', objectFit: 'contain'}} />
                                                                        <div>
                                                                            <p className="mb-0">
                                                                                {product.BundleValueAddedProduct.quantity} x {product.vap_name}
                                                                            </p>
                                                                            <p className="mb-0">
                                                                                {parseFloat(product.BundleValueAddedProduct.price) === 0 ? (
                                                                                    <span className="text-danger">Free!</span>
                                                                                ) : (
                                                                                    <>
                                                                                        {parseFloat(product.BundleValueAddedProduct.price) < parseFloat(product.vap_sell) && (
                                                                                            <span className="text-muted"><del>R{product.vap_sell}</del> </span>
                                                                                        )}
                                                                                        <span className="text-danger">R{product.BundleValueAddedProduct.price} once off</span>
                                                                                    </>
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className="card-price">
                                                                <h5>R{providerCategory.bundles[currentIndices[providerCategory.prov_cat_id] || 0].package_price}/pm</h5>
                                                            </div>
                                                            <button className="btn btn-trusc-primary" onClick={() => handleBundleSignUp(providerCategory.bundles[currentIndices[providerCategory.prov_cat_id] || 0])}>Sign Up</button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>No bundles available for this provider category.</div>
                                                )
                                            ) : (
                                                providerCategory.packages?.length > 0 && currentIndices[providerCategory.prov_cat_id] !== undefined ? (
                                                    <div className="package-card">
                                                        <img 
                                                            src={process.env.REACT_APP_IMAGE_URL + '/' + providerCategory.prov_cat_image_url} 
                                                            alt={providerCategory.packages[currentIndices[providerCategory.prov_cat_id]]?.package_name || ''} 
                                                            className="card-img-top" 
                                                        />
                                                        <div className="card-body">
                                                        <h5 className="card-title">
    {categoryViewStates[providerCategory.prov_cat_id] 
        ? providerCategory.bundles[currentIndices[providerCategory.prov_cat_id] || 0].bundle_name
        : providerCategory.packages[currentIndices[providerCategory.prov_cat_id] || 0].package_name}
</h5>
                                                            <div className="card-speeds">
                                                                <p>
                                                                    <i className="fa-solid fa-cloud-arrow-down"></i> {providerCategory.packages[currentIndices[providerCategory.prov_cat_id] || 0].download_speed_mb} Mbps
                                                                </p>
                                                                <p>
                                                                    <i className="fa-solid fa-cloud-arrow-up"></i> {providerCategory.packages[currentIndices[providerCategory.prov_cat_id] || 0].upload_speed_mb} Mbps
                                                                </p>
                                                            </div>
                                                            <div className="card-costs">
                                                                <p>
                                                                    <span className="cost-heading">Installation Cost: </span>
                                                                    {parseFloat(providerCategory.packages[currentIndices[providerCategory.prov_cat_id] || 0].installation) === 0 ? (
                                                                        <span style={{ color: 'red' }}>Free!</span>
                                                                    ) : (
                                                                        <>R{providerCategory.packages[currentIndices[providerCategory.prov_cat_id] || 0].installation}<span className="cost-post-text">/Once Off</span></>
                                                                    )}
                                                                </p>
                                                                <p className="card-text">
                                                                    <i className="fa-solid fa-calendar-alt"></i> Term: {providerCategory.packages[currentIndices[providerCategory.prov_cat_id] || 0].term} months
                                                                </p>
                                                            </div>
                                                            <div className="card-price">
                                                                <h5>R{providerCategory.packages[currentIndices[providerCategory.prov_cat_id] || 0].sell_price_incl}/pm</h5>
                                                            </div>
                                                            <button className="btn btn-trusc-primary" onClick={() => handleSignUp(providerCategory.packages[currentIndices[providerCategory.prov_cat_id] || 0])}>Sign Up</button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>No packages available for this provider category.</div>
                                                )
                                            )}
                                        </div>
                                        <button 
                                            className="btn btn-trusc-carousel" 
                                            onClick={(e) => {
                                                if (!e.currentTarget.disabled) {
                                                    handleNextCard(categoryIndex);
                                                }
                                            }}
                                            disabled={currentIndices[providerCategory.prov_cat_id] === 
                                                (categoryViewStates[providerCategory.prov_cat_id] 
                                                    ? (providerCategory.bundles?.length || 1) - 1
                                                    : (providerCategory.packages?.length || 1) - 1)
                                            }
                                        >❯</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {showSignUpForm && (
                        <SignUpForm
                            show={showSignUpForm}
                            handleClose={() => setShowSignUpForm(false)}
                            selectedPackage={selectedPackageOrBundle}
                            selectedProviderCategory={selectedProvider.provider_categories[0]}
                            address={data.coverageCheck.address}
                            latitude={latitude}
                            longitude={longitude}
                            tech_class_user_type={selectedTechClass.tech_class_user_type}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default PackagesDisplay;
